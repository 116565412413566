import { API_URL } from "@/_plugins/axios";
import withoutWatchersMixin from "@/_plugins/withoutWatchersMixin";
import debounce from "lodash/debounce";
import Multiselect from "vue-multiselect";
import Form from "@/_plugins/form";
import Pagination from "laravel-vue-pagination";
import HasError from "@/components/HasError";
import i18n from "@/_plugins/i18n";
import DetailsModal from "@/components/DetailsModal";
import { mapGetters } from "vuex";
// import { Skeleton } from "vue-loading-skeleton";

export default {
  mixins: [withoutWatchersMixin],
  name: "EquipmentDocument",
  components: {
    // HelloWorld,
    Multiselect,
    Pagination,
    HasError,
    DetailsModal,
    // Skeleton,
  },
  data() {
    return {
      deleting: false,
      usersEquipment: [],
      subcategoriesOptions: [],
      users: [],
      history: {},
      searchDropdown: false,
      equipment: [],
      equipmentCategories: [],
      subcategories: [],
      sectors: [],
      fileRecords: [],
      selectedUser: null,
      item: null,
      selectedEquipment: [],
      form: new Form({
        user_id: null,
        equipment_id: [],
      }),
      unrentForm: new Form({
        equipment_user_id: null,
        files: [],
        state: null,
      }),
      value: null,
      currentPage: 1,
      loading: false,
      loadingHistory: false,
      loadingExport: false,
      modalTitle: "Dodaj novi karton",
      filters: {
        functions: false,
        category: 0,
        subcategory: 0,
        sector: 0,
        date_range: "",
      },
      deleteState: "",
      unrentId: null,
      imageRecords: [],
    };
  },
  methods: {
    changeSubcategory() {
      this.filters.subcategory = 0;
      let subcategories = [];
      this.equipmentCategories.filter((item) => {
        if (item.id === this.filters.category)
          subcategories = item.subcategories;
      }),
        (this.subcategoriesOptions = [
          { id: 0, name: "Sve potkategorije" },
          ...subcategories,
        ]);
    },
    imagesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      validFileRecords.forEach((filee) => {
        this.unrentForm.files = this.unrentForm.files.concat(filee.file);
      });
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.unrentForm.files.indexOf(fileRecord);
      // queued file, not yet uploaded. Just remove from the arrays
      this.unrentForm.files.splice(i, 1);
      var k = this.fileRecords.indexOf(fileRecord);
      if (k !== -1) this.fileRecords.splice(k, 1);
    },
    imageDeleted: function (fileRecord) {
      var i = this.unrentForm.files.indexOf(fileRecord);
      if (i !== -1) {
        this.unrentForm.files.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    resetFilters() {
      this.filters.category = 0;
      this.filters.subcategory = 0;
      this.filters.sector = 0;
      this.filters.date_range = "";
    },
    selectEquipment() {
      this.form.equipment_id = [];
      if (!this.item) {
        this.selectedEquipment.forEach((item) => {
          this.form.equipment_id.push(item.id);
        });
      } else {
        this.form.equipment_id.push(this.selectedEquipment.id);
      }
    },
    unrented() {
      this.item = null;
      this.closeModal();
      this.getAllEquipment();
      this.getEquipmentDocuments();
    },
    detailsModalClosed() {
      this.item = null;
    },
    getAllCategories() {
      API_URL.get("category").then((response) => {
        this.equipmentCategories = response.data.data;
      });
    },
    getSubcategories() {
      API_URL.get("subcategory").then((response) => {
        this.subcategories = response.data;
      });
    },
    getAllSectors() {
      API_URL.get("sector").then((response) => {
        this.sectors = response.data.data;
      });
    },
    edit(i) {
      this.item = i;
      this.form.user_id = i.user_detail.id;
      this.form.rented_state = i.rented_state;
      this.form.equipment_id.push(i.equipment_detail.id);
      this.selectedEquipment = i.equipment_detail;
      this.selectedUser = i.user_detail;
      this.$bvModal.show("modal-1");
    },
    details(i) {
      this.item = i;
      this.$bvModal.show("details");
    },
    unrentEquipment() {
      console.log(this.unrentForm);
      this.unrentForm.post("unrent-equipment").then(() => {
        this.$toast.success(i18n.tc("notifications.success"));
        this.$bvModal.hide("deleteEquipment");
        this.currentPage = 1;
        this.loading = false;
        this.deleting = false;
        this.unrentId = null;
        this.deleteState = "";
        this.getEquipmentDocuments();
        this.getAllEquipment();
      });
      // let id = this.unrentId;
      // let state = this.deleteState;
      // this.loading = true;
      // this.deleting = true;

      // const queryParams = {
      // };
      //
      // let images = this.imageRecords
      //
      // API_URL.post(`unrent-equipment/${id}/${state}`, {
      //   images,
      // }).then(() => {
      //   this.$toast.success(i18n.tc("notifications.success"));
      //   this.$bvModal.hide("deleteEquipment");
      //   this.currentPage = 1;
      //   this.loading = false;
      //   this.deleting = false;
      //   this.unrentId = null;
      //   this.deleteState = "";
      //   this.getEquipmentDocuments();
      //   this.getAllEquipment();
      // });
    },
    unrent(id, state) {
      this.unrentForm.equipment_user_id = id;
      this.unrentForm.state = state;
      this.unrentId = id;
      this.deleteState = state;
      this.$bvModal.show("deleteEquipment");
      // this.loading = true;
      // let rs = prompt(`Razduzeno stanje (${state})`);
      // if (rs) {
      //   API_URL.delete(`unrent-equipment/${id}/${rs ? rs : state}`).then(() => {
      //     this.$toast.success(i18n.tc("notifications.success"));
      //     this.currentPage = 1;
      //     this.loading = false;
      //     this.getEquipmentDocuments();
      //     this.getAllEquipment();
      //   });
      // } else {
      //   this.loading = false;
      //   // this.$toast.warning("Morate unijeti razduzeno stanje");
      // }
    },
    closeModal() {
      this.form.reset();
      this.item = null;
      this.selectedEquipment = [];
      this.selectedUser = null;
      this.fileRecords = [];
    },
    submitForm() {
      if (this.item !== null) {
        this.form
          .put(`equipment-user/${this.item.id}`)
          .then(() => {
            this.getEquipmentDocuments();
            this.getAllEquipment();
            this.$toast.success(i18n.tc("notifications.success"));
            this.$bvModal.hide("modal-1");
          })
          .catch(() => {
            // console.log("ERROR", error.data);
          });
      } else {
        this.form
          .post("rent-equipment")
          .then(() => {
            this.getEquipmentDocuments();
            this.getAllEquipment();
            this.$toast.success(i18n.tc("notifications.success"));
            this.$bvModal.hide("modal-1");
          })
          .catch(() => {
            // console.log("ERROR", error.data);
          });
      }
    },
    getAllUsers() {
      API_URL.get("users/all").then((response) => {
        this.users = response.data;
      });
    },
    getAllEquipment() {
      API_URL.get("available-equipment").then((response) => {
        this.equipment = response.data.data;
        // this.equipment.forEach((item) => {
        //   item.name = `${item.name} (${item.state})`;
        // })
      });
    },
    closeSearchDropdown() {
      this.searchDropdown = false;
    },
    getEquipmentDocuments() {
      this.loading = true;
      API_URL.get("users-equipment", {
        params: {
          page: this.currentPage,
          ...this.filters,
        },
      }).then((response) => {
        this.usersEquipment = response.data;
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapGetters({
      exporting: "exporting",
    }),
    categoriesOptions() {
      return [{ id: 0, name: "Sve kategorije" }, ...this.equipmentCategories];
    },
    // subcategoriesOptions() {
    //   // return [{ id: 0, name: "Sve potkategorije" }, ...this.subcategories];
    // },
    sectorOptions() {
      return [{ id: 0, name: "Svi sektori" }, ...this.sectors];
    },
    resultsCount() {
      return this.usersEquipment?.data?.length;
    },
  },
  watch: {
    filters: {
      deep: true,
      immediate: false,
      // eslint-disable-next-line no-unused-vars
      handler: debounce(function (value) {
        this.$withoutWatchers(() => (this.currentPage = 1));
        this.getEquipmentDocuments();
      }, 250),
    },
    currentPage() {
      this.getEquipmentDocuments();
    },
  },
  mounted() {
    this.getEquipmentDocuments();
    this.getAllUsers();
    this.getAllEquipment();
    this.getAllCategories();
    this.getSubcategories();
    this.getAllSectors();
    this.$emit("loadBreadcrumbLink", {
      pageName: "Aktivna zaduženja",
    });
  },
};
